import "../../../../styles/searchHome.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

// SearchHome sem o fundo com a imagem
export default function SearchHomeBottomless() {
  const [search, setSearch] = useState("");

  const navigate = useNavigate();

  const selectOpen = (e) => {
    e.preventDefault();
    navigate(`/pesquisar/${search}/`);
  };

  return (
    <div id="back-search-main-portal">
      <form className="container-search">
        <input
          type="text"
          placeholder="Buscar"
          name="search-main"
          className="input-search-dois"
          onChange={(e) => setSearch(e.target.value)}
        />
        <button onClick={(e) => selectOpen(e)} className="link-search-dois">
          <img
            src="/icons/search.svg"
            alt="logo buscar"
            className="svg-search"
          />
        </button>
      </form>
    </div>
  );
}
