const idPortalApi = "b64b72c18bc14589f24481ca76b3c6df81eafa30";

module.exports = {
  idPortalApi: `${idPortalApi}`,
  tokenLink: `${idPortalApi}`, //Token para acessar o WWW.transparência
  // Produção
  urlApi: `https://api-portais-workcenter.com.br/${idPortalApi}`,
  // urlApi: `http://localhost:8004/${idPortalApi}`,
  urlADM: `https://administracaopublica.com.br`,

  dnsPortal: "altoparnaiba.ma.gov.br",

  NomePortal: "Alto Parnaíba",
  ufPortal: "PI",
  NomeTipoPortal: "Prefeitura Municipal", //Prefeitura ou Câmara
  TipoPortal: 3, //3 = Prefeitura 4 = Câmara

  //Variável em teste (newsViewRoute)
  newsViewRoute: true, //Essa opção informa se a rota de visualização de uma única noticia está disponível (Raposa gera as noticias pelo lado do servidor com nextjs)

  countNewsHome: 6, //quantidade de noticias na home

  RoutesDaily: true, //Deixa ativado as rotas para a pagina de diario

  link: "http://www.transparenciadministrativa.com.br", //Onde o token abaixo ser[a utilizado]
  linkArquivos: "http://www.transparenciadministrativa.com.br/portalimagem", //Onde o token abaixo ser[a utilizado]

  urlEmDeploy: "https://altoparnaiba.ma.gov.br/", //usada para link de compartilhamento
  imagemURL: "https://cdn.administracaopublica.com.br/complementares/", //link para a imagem
  //imagemURL: 'http://localhost:8004/uploads/', //link para a imagem

  corIcones: "#000080",
  link_youtube: `#`,
  link_localize: `https://maps.app.goo.gl/skQsVenYfubcCUuP8`,

  link_mapa: `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10357.85665265238!2d-45.940426441608295!3d-9.112457898745763!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x932c385a49c2d3e9%3A0x58a1c575b6c8780d!2sAlto%20Parna%C3%ADba%2C%20State%20of%20Maranh%C3%A3o!5e0!3m2!1sen!2sbr!4v1739394447619!5m2!1sen!2sbr`,
};
